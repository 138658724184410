import VoiceTask from '../../components/project/VoiceTask';

const VoiceTaskPage = () => {
  return (
    <div>
      <VoiceTask />
    </div>
  );
};

export default VoiceTaskPage;