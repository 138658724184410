import React from 'react';

const AdminPage = () => {
  return (
    <div className='admin'>
      <h1>Admin Page</h1>
    </div>
  );
};

export default AdminPage;