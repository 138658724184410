import CreateTaskList from '../../components/project/CreateTaskList';

const CreateTaskListPage = () => {
  return (
    <div>
      <CreateTaskList />
    </div>
  );
};

export default CreateTaskListPage;