import VoiceChat from '../../components/project/VoiceChat';

const VoiceChatPage = () => {
  return (
    <div>
      <VoiceChat />
    </div>
  );
};

export default VoiceChatPage;